const createMediaQuery = (n: string) => `@media screen and (min-width:${n})`;

const addAliases = (arr: any[], newAliases: string[]) =>
  newAliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i];
      },
    })
  );

export const breakpoints = [480, 576, 768, 992, 1200, 1600].map(n => n + 'px');

export const mediaQueries = breakpoints.map(createMediaQuery);

const aliases = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

addAliases(breakpoints, aliases);
addAliases(mediaQueries, aliases);

export const space = [0, 4, 8, 18, 36, 72, 144, 288];

export const font = `proxima-nova,"Proxima Nova",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`;
export const fonts = {
  marketing: `'MaisonNeue',${font}`,
  sans: font,
  serif: 'trumpgothicpro, sans-serif',
  heading: 'trumpgothicpro, sans-serif',
};

export const fontSizes = [12, 14, 16, 19, 21, 24, 32, 48, 56, 72];

export const light = 100;
export const medium = 400;
export const semibold = 500;
export const bold = 700;
// alias
export const regular = medium;

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const fontWeights = {
  light,
  medium,
  bold,
  semibold,
  // alias
  regular,
};

export const lineHeights = {
  standard: 1.5,
  display: 1.25,
};

const letterSpacings = {
  normal: 'normal',
  caps: 'normal',
};

export const textStyles = {
  display8: {
    fontSize: fontSizes[8] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display7: {
    fontSize: fontSizes[7] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display6: {
    fontSize: fontSizes[6] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display5: {
    fontSize: fontSizes[5] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display4: {
    fontSize: fontSizes[4] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display3: {
    fontSize: fontSizes[3] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
    letterSpacing: letterSpacings.caps,
    textTransform: 'uppercase',
  },
  body2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
  body1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
  body0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
};

const colors = {
  black: '#161616', // app sidebar
  borderGray: '#D9D9D9',
  text: 'rgba(0, 0, 0, 0.9)',
  // black: '#3f3f3f',
  grayDark: '#3f3f3f',
  gray: '#D9D9D9',
  grayStrong: '#6F6F6F',
  lightGray: '#f4f6f8',
  red: '#c45955',
  redFaded: 'rgba(196, 89, 85, 0.2)',
  lightRed: 'rgba(196, 89, 85, 0.2)',
  yellow: '#e5c381',
  yellowFaded: 'rgba(229, 195, 129, 0.2)',
  green: '#a0c192',
  greenFaded: 'rgba(160, 193, 146, 0.2)',
  lightGreen: 'rgba(160, 193, 146, 0.2)',
  darkBlue: '#bfd4d9',
  blue: '#6ba8bf',
  blueFaded: 'rgba(107, 168, 191, 0.2)',
  lightBlue: 'rgba(107, 168, 191, 0.2)',
  purple: '#9c81c9',
  purpleFaded: 'rgba(156, 129, 201, 0.2)',
  lightPurple: 'rgba(156, 129, 201, 0.2)',
  white: '#fff',
  whiteSmoke: '#FEFEFE',
  whiteApple: '#FAFAFA',
  greenBlueGradient:
    'linear-gradient(169.37deg, rgba(160, 193, 146, 1) -28.75%, rgba(107, 168, 191, 1) 55.73%)',
  greenBlueGradientFaded:
    'linear-gradient(169.37deg, rgba(160, 193, 146, 0.1) -28.75%, rgba(107, 168, 191, 0.1) 55.73%)',
  blackOpaque02: 'rgba(0, 0, 0, 0.02)',
  blackOpaque05: 'rgba(0, 0, 0, 0.05)',
  blackOpaque1: 'rgba(0, 0, 0, 0.1)',
  blackOpaque2: 'rgba(0, 0, 0, 0.2)',
  blackOpaque3: 'rgba(0, 0, 0, 0.3)',
  blackOpaque4: 'rgba(0, 0, 0, 0.4)',
  blackOpaque5: 'rgba(0, 0, 0, 0.5)',
  blackOpaque6: 'rgba(0, 0, 0, 0.6)',
  blackOpaque7: 'rgba(0, 0, 0, 0.7)',
  blackOpaque8: 'rgba(0, 0, 0, 0.8)',
  blackOpaque9: 'rgba(0, 0, 0, 0.9)',
  redOpaque1: 'rgba(196, 89, 85, .1)',
  redOpaque2: 'rgba(196, 89, 85, .2)',
  redOpaque3: 'rgba(196, 89, 85, .3)',
  redOpaque4: 'rgba(196, 89, 85, .4)',
  redOpaque5: 'rgba(196, 89, 85, .5)',
  redOpaque6: 'rgba(196, 89, 85, .6)',
  redOpaque7: 'rgba(196, 89, 85, .7)',
  redOpaque8: 'rgba(196, 89, 85, .8)',
  redOpaque9: 'rgba(196, 89, 85, .9)',
  red1: '#fff3f0',
  red2: '#f7ebe9',
  red3: '#ebd8d5',
  red4: '#deaca6',
  red5: '#d1817b',
  red6: '#c45955',
  red7: '#9e3c3c',
  red8: '#78282a',
  red9: '#52171b',
  red10: '#2b0c0f',
  yellowOpaque1: 'rgba(229, 195, 129, .1)',
  yellowOpaque2: 'rgba(229, 195, 129, .2)',
  yellowOpaque3: 'rgba(229, 195, 129, .3)',
  yellowOpaque4: 'rgba(229, 195, 129, .4)',
  yellowOpaque5: 'rgba(229, 195, 129, .5)',
  yellowOpaque6: 'rgba(229, 195, 129, .6)',
  yellowOpaque7: 'rgba(229, 195, 129, .7)',
  yellowOpaque8: 'rgba(229, 195, 129, .8)',
  yellowOpaque9: 'rgba(229, 195, 129, .9)',
  yellow1: '#fffcf0',
  yellow2: '#fffcf0',
  yellow3: '#fffbf0',
  yellow4: '#fff7e0',
  yellow5: '#f2deae',
  yellow6: '#e5c381',
  yellow7: '#bf9d62',
  yellow8: '#997846',
  yellow9: '#73552f',
  yellow10: '#4d371f',
  greenOpaque1: 'rgba(160, 193, 146, .1)',
  greenOpaque2: 'rgba(160, 193, 146, .2)',
  greenOpaque3: 'rgba(160, 193, 146, .3)',
  greenOpaque4: 'rgba(160, 193, 146, .4)',
  greenOpaque5: 'rgba(160, 193, 146, .5)',
  greenOpaque6: 'rgba(160, 193, 146, .6)',
  greenOpaque7: 'rgba(160, 193, 146, .7)',
  greenOpaque8: 'rgba(160, 193, 146, .8)',
  greenOpaque9: 'rgba(160, 193, 146, .9)',
  green1: '#f7fff0',
  green2: '#ecf5e6',
  green3: '#e0e8da',
  green4: '#d3dbce',
  green5: '#c4cfbe',
  green6: '#a0c192',
  green7: '#7a9c6e',
  green8: '#57754d',
  green9: '#364f30',
  green10: '#1b2918',
  blues: [
    '#f0fdff',
    '#e4f0f2',
    '#d8e3e6',
    '#bfd4d9',
    '#93bfcc',
    '#6ba8bf',
    '#4e8399',
    '#355e73',
    '#1f3c4d',
    '#0f1d26',
  ],
  blueOpaque1: 'rgba(107, 168, 191, 0.1)',
  blueOpaque2: 'rgba(107, 168, 191, 0.2)',
  blueOpaque3: 'rgba(107, 168, 191, 0.3)',
  blueOpaque4: 'rgba(107, 168, 191, 0.4)',
  blueOpaque5: 'rgba(107, 168, 191, 0.5)',
  blueOpaque6: 'rgba(107, 168, 191, 0.6)',
  blueOpaque7: 'rgba(107, 168, 191, 0.7)',
  blueOpaque8: 'rgba(107, 168, 191, 0.8)',
  blueOpaque9: 'rgba(107, 168, 191, 0.9)',
  blue1: '#f0fdff',
  blue2: '#e4f0f2',
  blue3: '#d8e3e6',
  blue4: '#bfd4d9',
  blue5: '#93bfcc',
  blue6: '#6ba8bf',
  blue7: '#4e8399',
  blue8: '#355e73',
  blue9: '#1f3c4d',
  blue10: '#0f1d26',
  purpleOpaque1: 'rgba(156, 129, 201, .1)',
  purpleOpaque2: 'rgba(156, 129, 201, .2)',
  purpleOpaque3: 'rgba(156, 129, 201, .3)',
  purpleOpaque4: 'rgba(156, 129, 201, .4)',
  purpleOpaque5: 'rgba(156, 129, 201, .5)',
  purpleOpaque6: 'rgba(156, 129, 201, .6)',
  purpleOpaque7: 'rgba(156, 129, 201, .7)',
  purpleOpaque8: 'rgba(156, 129, 201, .8)',
  purpleOpaque9: 'rgba(156, 129, 201, .9)',
  purple1: '#f8f0ff',
  purple2: '#f5edfc',
  purple3: '#e8e1f0',
  purple4: '#dbd5e3',
  purple5: '#bdabd6',
  purple6: '#9c81c9',
  purple7: '#7860a3',
  purple8: '#56437d',
  purple9: '#372a57',
  purple10: '#1e1730',

  pageBackground: '#FAFAFA',
  inputPlaceholderText: '#BFBFBF',
  headerBackground: 'rgba(250, 250, 250, 0.98)',
  headerNavLink: '#6F6F6F',
};

export { colors };

export const colorStyles = {
  whiteOnText: {
    color: colors.white,
    backgroundColor: colors.text,
  },
  whiteOnGray: {
    color: colors.white,
    backgroundColor: colors.gray,
  },
  textOnLightGray: {
    color: colors.text,
    backgroundColor: colors.lightGray,
  },
  whiteOnBlue: {
    color: colors.white,
    backgroundColor: colors.blue,
  },
  blueOnLightBlue: {
    color: colors.blue,
    backgroundColor: colors.lightBlue,
  },
  whiteOnGreen: {
    color: colors.white,
    backgroundColor: colors.green,
  },
  greenOnLightGreen: {
    color: colors.green,
    backgroundColor: colors.lightGreen,
  },
  whiteOnRed: {
    color: colors.white,
    backgroundColor: colors.red,
  },
  redOnLightRed: {
    color: colors.red,
    backgroundColor: colors.lightRed,
  },
  whiteOnPurple: {
    color: colors.white,
    backgroundColor: colors.purple,
  },
  purpleOnLightPurple: {
    color: colors.purple,
    backgroundColor: colors.lightPurple,
  },
  textOnWhite: {
    color: colors.text,
    backgroundColor: colors.white,
  },
  grayOnWhite: {
    color: colors.gray,
    backgroundColor: colors.white,
  },
  blueOnWhite: {
    color: colors.blue,
    backgroundColor: colors.white,
  },
  greenOnWhite: {
    color: colors.green,
    backgroundColor: colors.white,
  },
  redOnWhite: {
    color: colors.red,
    backgroundColor: colors.white,
  },
  purpleOnWhite: {
    color: colors.purple,
    backgroundColor: colors.white,
  },
};

// @ts-ignore
colorStyles.info = colorStyles.textOnLightGray;
// @ts-ignore
colorStyles.success = colorStyles.whiteOnGreen;
// @ts-ignore
colorStyles.warning = colorStyles.textOnOrange;
// @ts-ignore
colorStyles.danger = colorStyles.whiteOnRed;

// styled-system's `borderRadius` function can hook into the `radii` object/array
export const radii = [0, 2, 5];
export const radius = '5px';

export const maxContainerWidth = '1600px';

// boxShadows
export const boxShadows = [
  `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)`,
];

// animation duration
export const duration = {
  fast: `150ms`,
  normal: `300ms`,
  slow: `450ms`,
  slowest: `600ms`,
};

// animation easing curves
const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)';
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)';

const timingFunctions = {
  easeInOut,
  easeOut,
  easeIn,
};

// animation delay
const transitionDelays = {
  small: `60ms`,
  medium: `160ms`,
  large: `260ms`,
  xLarge: `360ms`,
};

const buttonSizes = {
  small: `30px`,
  medium: `36px`,
  large: `40px`,
};

const borders = {
  default: `1px solid ${colors.borderGray}`,
};

const shadows = {
  card: '0px 8px 16px rgba(153, 153, 153, 0.04)',
  cardLarge: '0px 10px 26px rgba(0, 0, 0, 0.1)',
};

export default {
  bold,
  borders,
  boxShadows,
  breakpoints,
  buttonSizes,
  colors,
  colorStyles,
  duration,
  font,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  light,
  lineHeights,
  maxContainerWidth,
  mediaQueries,
  radii,
  radius,
  regular,
  semibold,
  shadows,
  space,
  textStyles,
  timingFunctions,
  transitionDelays,
};
